.animate-typing {
	color: #747474;
	padding: 1rem;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 0.5rem;
	position: relative;
	padding-left: 40px;
	width: 100%;
	i {
		position: absolute;
		top: 17px;
		left: 15px;
	}
	.name {
		font-weight: 600;
		color: #32383f;
	}
	.dot {
		display: inline-block;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		margin-right: -1px;
		background: $brand;
		animation: wave 1.3s linear infinite;
		opacity: 0.6;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}
.typing-wrap {
	position: absolute;
	top: -4rem;
	box-sizing: border-box;
}
@keyframes wave {
	0%,
	60%,
	100% {
		transform: initial;
	}

	30% {
		transform: translateY(-5px);
	}
}

.icon-button {
	display: inline-flex;
	align-content: center;
	justify-content: center;
	padding: 0.5rem;
	width: 40px;
	height: 40px;
	i {
		font-size: 1.5rem;
	}
	cursor: pointer;
	&:hover {
		background-color: #f4f4f4;
	}
}
.tooltipp {
	position: relative;
}

.tooltipp .tooltipptext {
	visibility: hidden;
	background-color: #fff;
	border: 1px #ccc solid;
	color: #32383f;
	text-align: center;
	border-radius: 3px;
	padding: 4px;
	min-width: 60px;
	font-size: 0.825rem;
	/* Position the tooltip */
	position: absolute;
	bottom: -30px;
	z-index: 9999;
	font-weight: normal;
	display: inline-block;
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.tooltipp:hover .tooltipptext {
	visibility: visible;
}
.chat-user-list {
	flex-grow: 1;
}
.room-info-box {
	background-color: #f4f4f4;
	padding: 1rem 1.5rem;
	box-sizing: border-box;
	border-radius: 0.25rem;
	margin: 0.825rem 0;

	&-row {
		display: flex;
		align-content: center;
		line-height: 180%;
		span {
			width: 60px;
			color: #32383f;
		}
	}
}
.chat {
	&-count {
		// background: #32383F;
		background: #d7000f;
		color: #fff;
		padding: 0.1rem 0.45rem;
		border-radius: 1rem;
		margin-top: 0.25rem;
	}
	&-index {
		position: relative;
		&.new:before {
			content: '';
			right: -1rem;
			position: absolute;
			background: #d7000f;
			width: 0.6rem;
			height: 0.6rem;
			border-radius: 1rem;
			color: #fff;
			font-size: 0.825rem;
		}
	}
}
.navigation-link-info.new:before {
	content: '';
	right: 0.25rem;
	position: absolute;
	background: #d7000f;
	width: 0.6rem;
	height: 0.6rem;
	border-radius: 1rem;
	border: 1px solid #fff;
}
.chat-main-zone {
	height: 100%;
}
.chat-input-zone {
	background: #f4f4f4 !important;
	box-shadow: none !important;
	border: none !important;
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
	max-height: 70px;
	font-weight: normal;
}

.accordion {
	&-item {
		// border: 1px #ccc solid;
		margin-bottom: 0.5rem;
	}
	&-header {
		border: 1px #ccc solid;
		border-radius: 0.25rem;
		overflow: hidden;
		color: #32383f !important;
	}
}
.chat-sub-list-empty {
	height: 50vh !important;
	background: url(../../assets/list-empty.svg) 50% 50% no-repeat;
	background-size: contain;
	opacity: 0.6;
	text-align: center;
}
.chat-sub-list-wrap {
	height: 75vh;
}
.ri-heart-fill.active {
	// background-color: #D7000F;
	color: #d7000f;
}

.bg-l25-info,
.bg-lo25-info {
	// background: #f4f4f4 !important;
}
.message {
	&-wrap {
		// background: #f4f4f4;
		margin: 0.25rem 0;
		padding: 0.5rem 1rem !important;
		min-height: 56px;
		&:hover {
			background: #f4f4f4;
		}
		&.active {
			background: #f4f4f4;
		}
		&.smart {
			background: #ffe5e5;
		}
	}
	&-mid {
		flex-grow: 1;
		overflow: hidden;
		&-info {
			font-size: 14px;
			font-weight: bold;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-break: break-all;
			i {
				color: #5e5e5e;
				font-weight: normal;
			}
			.usercount {
				color: #5e5e5e;
				font-weight: normal;
				font-size: 12px;
				margin-right: 0.825rem;
			}
		}
		&-text {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-break: break-all;
			font-size: 14px;
			font-weight: normal;
			color: #5e5e5e;
		}
	}
	&-date {
		color: rgb(160, 160, 160) !important;
		min-width: 80px;
		display: inline-block;
		text-align: right;
		font-size: 0.825rem;
	}
}
.id-wrap {
	font-size: 12px;
	font-weight: normal;
	padding: 0.25rem 0.5rem;
	border-radius: 0.25rem;
	display: inline-block;
	background: #ececec;
	margin-left: 0.5rem;
}
.chat-invite {
	position: absolute;
	right: 1.5rem;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2) !important;
}
.mesg-info {
	flex-grow: 1;
	width: 100%;
	// position: absolute;
	display: flex;
	justify-content: center;
	margin: 0.25rem;
	font-size: 12px;
	& > span {
		display: inline-block;
		background-color: #f2f4f8;
		padding: 0.25rem 0.5rem;
		border-radius: 0.5rem;
		color: #747474;
		&:nth-child(2) {
			background: none;
			color: #999999;
		}
		& > span {
			// font-weight: bold;
			color: #32383f;
		}
	}
}
.chat-add-user {
	display: flex;
	// justify-content: space-between;
	align-content: center;
	line-height: 260%;
	cursor: pointer;
	position: relative;
	width: 100%;
	border-radius: 4px;
	padding-right: 2rem;
	&:hover {
		background: #f4f4f4;
	}
	&:before {
		content: '';
		position: absolute;
		top: 12px;
		right: 5px;
		background: url(../../assets/add-fill.svg) 50% 50% no-repeat;
		width: 12px;
		height: 12px;
		border-radius: 6px;
	}
	div {
		text-align: left;
		&:nth-child(1) {
			padding-left: 30px;
			width: 90px;
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			&.chat-user-ing:before {
				content: '';
				position: absolute;
				top: 12px;
				left: 12px;
				background: #13ff3a;
				width: 12px;
				height: 12px;
				border-radius: 6px;
			}
			&.chat-user-out:before {
				content: '';
				position: absolute;
				top: 12px;
				left: 12px;
				background: #9e9e9e;
				width: 12px;
				height: 12px;
				border-radius: 6px;
			}
		}
		&:nth-child(2) {
			width: 120px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			color: #747474;
		}
		&:nth-child(3) {
			width: 80px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			color: #747474 !important;
		}
	}
}
.chat-mus-user {
	display: flex;
	// justify-content: space-between;
	align-content: center;
	line-height: 260%;
	cursor: pointer;
	position: relative;
	width: 100%;
	border-radius: 4px;
	padding-right: 2rem;
	border-radius: 0.25rem;
	border: 1px #ccc solid;
	margin: 0.25rem 0;
	&:hover {
		background: #f4f4f4;
	}
	&:before {
		content: '';
		position: absolute;
		top: 12px;
		right: 12px;
		background: url(../../assets/close-fill.svg) 50% 50% no-repeat;
		width: 12px;
		height: 12px;
		border-radius: 6px;
	}
	div {
		text-align: left;
		&:nth-child(1) {
			padding-left: 30px;
			width: 90px;
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			&.chat-user-ing:before {
				content: '';
				position: absolute;
				top: 12px;
				left: 12px;
				background: #13ff3a;
				width: 12px;
				height: 12px;
				border-radius: 6px;
			}
			&.chat-user-out:before {
				content: '';
				position: absolute;
				top: 12px;
				left: 12px;
				background: #9e9e9e;
				width: 12px;
				height: 12px;
				border-radius: 6px;
			}
		}
		&:nth-child(2) {
			width: 120px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			color: #747474;
		}
		&:nth-child(3) {
			width: 80px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			color: #272727 !important;
		}
	}
}
.scroll-zone {
	overflow-y: auto;
	height: 65vh;
	// margin-top: 1rem;
}
.ri-group-chat {
	width: 1.5rem;
	height: 1.5rem;
	position: relative;
	&:before {
		position: absolute;
		content: '';
		background: url(../../assets/group-chat.svg) 50% 50% no-repeat;
		background-size: contain;
		top: 4px;
		width: 1.5rem;
		height: 1.5rem;
		opacity: 0.7;
	}
}
.chat-info-user-wrap {
	display: flex;
	flex-wrap: wrap;
	& > div.chat-info-user-row {
		width: 100%;
		&:hover {
			background: #f4f4f4;
			cursor: auto;
		}
	}
}
.chat-info-user {
	position: relative;
	flex-grow: 1;
	&-crown {
		position: absolute;
		top: 0;
		left: -45px;
		color: #d7000f;
		background: #fff;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
	}
}
.wrap {
	&-duty {
		display: inline-block;
		color: #747474;
		margin-left: 0.5rem;
		font-size: 12px;
	}
	&-dpt {
		display: inline-block;
		color: #999999;
	}
}
.active.ri-bookmark-3-fill {
	color: #ffc400;
}

.country-option {
	width: 50%;
}
.opa-50 {
	opacity: 0.5;
}
.chat-user-table {
	min-width: 360px !important;
}
.chat-user-btn-zone {
	align-items: center;
	justify-content: center;
}
.chat-message.chat-message-reply > .read-count {
	position: absolute;
	left: -20px;
	bottom: 0;
	// color: #ffc400;
	color: #2f3268;
	display: inline-block;
	cursor: pointer;
}
.chat-message > .read-count {
	position: absolute;
	right: -20px;
	bottom: 0;
	color: #2f3268;
	display: inline-block;
	cursor: pointer;
}
// 스마트 알림 스타일 추가
.chat-message.smart {
	background: #ffe5e5 !important;
	border: 1px solid #ffa0a0;
	&:before {
		content: '';
		position: absolute;
		left: -1.5rem;
		width: 1.25rem;
		height: 1.25rem;
		background: url(../../assets/img/noti.svg) 50% 50% no-repeat;
	}
}
.alert-zone {
	display: flex;
	justify-content: flex-end;
}
.smart-icon {
	display: inline-block;
	padding: 0.1rem 0.45rem;
	border-radius: 1rem;
	margin-top: 0.25rem;
	width: 1.25rem;
	height: 1.25rem;
	background: url(../../assets/img/noti.svg) 50% 50% no-repeat;
}

// 추가된 조직도 css
.depart-user {
	display: flex;
	// justify-content: space-between;
	align-content: center;
	line-height: 180%;
	cursor: pointer;
	position: relative;
	width: 100%;
	border-radius: 4px;
	padding-right: 2rem;
	div {
		text-align: left;
		&:nth-child(1) {
			//   padding-left:30px;
			width: 24px;
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			&.chat-user-ing:before {
				content: '';
				position: absolute;
				top: 6px;
				// left:12px;
				background: #13ff3a;
				width: 12px;
				height: 12px;
				border-radius: 6px;
			}
			&.chat-user-out:before {
				content: '';
				position: absolute;
				top: 6px;
				// left:12px;
				background: #9e9e9e;
				width: 12px;
				height: 12px;
				border-radius: 6px;
			}
		}
	}
}
.depart-user {
	svg {
		color: #d7000f;
	}
}

.security {
	z-index: 2;
}

.security-container {
	opacity: 0.7;
	background-image: url(../../assets/warter-mark.png);
	background-repeat: repeat;
	background-size: 150px;
	z-index: 2;
	position: absolute;
	top: 22px;
	left: 0;
	width: 100%;
}

.security-lightbox {
	opacity: 0.7;
	background-image: url(../../assets/warter-mark.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 450px;
	z-index: 1001;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
}
