/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';
@import 'node_modules/bootstrap/scss/utilities/api';
@import 'custom/index';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');

:root {
	--bs-body-bg: #edeff5;
}
body::-webkit-scrollbar {
	width: 2px;
	/* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
	background: #edeff5;
	/* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
	background-color: gray;
	/* color of the scroll thumb */
	border-radius: 20px;
	/* roundness of the scroll thumb */
	border: 4px solid rgba(226, 106, 0, 1);
	/* creates padding around scroll thumb */
}

body {
	font-family: 'Noto Sans KR', sans-serif;
	overflow-x: hidden;
	overflow-y: auto;
}


// 로그인-회원가입
.main-bg {
	background: url(../assets/login_bg.png) 100% 0 no-repeat;
}
.main-bg::before {
	content: '';
	opacity: 0.3;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #000;
}
// 사이드메뉴
.aside-logo {
	background: url(../assets/logo_white.png) 50% 50% no-repeat;
	background-size: contain;
	margin-left: 1rem;
	a {
		display: block;
		width: 120px;
		height: 65px;
	}
}
.install-logo {
	background: url(../assets/logo.png) 50% 50% no-repeat;
	background-size: contain;
	margin-left: 1rem;
	a {
		display: block;
		width: 120px;
		height: 65px;
	}
}
.user-state {
	background: $info;
}
.admin-state {
	background: $success;
}

.color-blue {
	&-light {
		color: #7e9bff;
	}
	&-bg {
		color: #e0e9ff;
	}
}
.bg {
	&-blue {
		background: #e0e9ff;
	}
}
// 직원관리
.tbs {
	&-ita01 {
		th {
			background-color: #f8f8f8;
		}
	}
}

//공통
.font-num {
	font-family: 'Montserrat', sans-serif !important;
	&-100 {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 100;
	}
	&-500 {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 700;
		font-size: 3.5rem;
	}
}

.icon_box {
	display: inline-block;
	vertical-align: top;
	width: 180px;
	height: 180px;
	background: url(../assets/img/logo_.png) 50% 50% no-repeat;
	background-size: 100%;
	border-radius: 1rem;
	margin-bottom: 30px;
	box-shadow: rgba(255, 255, 255, 0.1);
}

a {
	text-decoration: none;
}

.txt_box strong {
	text-decoration: none;
	display: block;
	font-size: 40px;
	line-height: 50px;
	font-weight: 500;
}
.txt_box p {
	text-decoration: none;
	font-size: 16px;
	line-height: 32px;
	margin-top: 5px;
}

.pointer {
	cursor:	pointer;
}

.borderBottom0 {
	border-radius: 20px !important;
	border-bottom: 0px solid #e9e9e9;
	// box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
	font-weight: 800;
}

.borderBottom1 {
	border-radius: 20px !important;
	// border-bottom: 1px solid #e9e9e9;
	font-weight: 800;
}

.title-label {
	background-color: #e0e9ff;
	color: #000;
}

.belt-header {
	background-color: rgba(226, 106, 0, 1);
	box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.3);
}

.belt-header .title {
	color: #fff;
}

.table-title {
	color: rgba(226, 106, 0, 1) !important;
}

.form-item {
	margin-top: 1.2rem;
	margin-left: 1.2rem;
}

.border0 {
	border: 0px !important;
}