.board-card{
  padding:2rem;
}
.table-stb1{
  thead{
    border-top:1px #222 solid;
    background: #F6F6F6 !important;
    tr{
      vertical-align: -webkit-baseline-middle;
      th{
        padding: 1rem 0 !important;
        // height: 2rem;
      }      
    }
  }
  tbody{
    tr{
      td{
        padding:0.75rem 0;
      }
    }
  }
}
.text-left{
  text-align: left !important;
}
.fc-gray{
  color: #999;
}
.board{
  &-select{
    width: 8rem !important;
  }
  &-input{
    width: 20rem !important;
  }
  &-btn{
    background: #32383F;
    color:#fff !important;
    border:1px #32383F solid;
    :hover{
      color:#32383F !important;
    }
  }
  &-view{
    border-top: 1px #32383f solid !important;
  }
}
.page{
  &-item{
    &.active{
      .page-link{
        background: #32383F !important;
        border: 1px #32383f solid;
      }      
    }
    .page-link{
      background: #fff;
      border: 1px #ccc solid;
    }
  }  
}
.board-file{
  padding-top: 1.5rem;
  border-top:1px #ccc solid;
  &-head{
    width: 12rem;
    padding:2rem;
    background-color: rgb(238, 238, 238);
    color: #32383f;
    vertical-align: middle;
    font-weight: bold;
    display: flex;
    position:relative;
    span{
      align-self: center;
      vertical-align: middle;
    }
    // &:before{
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 1px;  
    //   display: block;    
    //   left:0;
    //   top:0;      
    //   background-color: #ccc;      
    // }
  }
  &-box{
    padding:1rem;
    flex-grow: 1;
    // padding-left:12rem;
    position: relative;  
    // background: #F6F6F6;  
  }
  &-list{
    line-height: 140%;
    padding:0.25rem;
    color:rgb(126, 126, 126);
    &:hover{
      background-color: rgb(238, 238, 238);
    }
  }
}

// .a-reply{
//   border-bottom:1px #ccc solid;
// }
.reply{
  &-zone{
    position: relative;
    border:1px #ccc solid;
  }
  &-write{
    width:95%;
    border:0 !important;
    height: 6.25em;
    border: none;    
    min-height: 60px !important;
    height: 60px;
    box-shadow: none !important;
  }
  &-btn{
    position: absolute;
    bottom:14px;
    right:10px;
  }
  &-view{
    background: #f4f4f4;
    padding:1rem;
  }
  &-sub{
    background: #fff;  
    padding:1rem !important;
  }
  &-my{
    position:relative;
    border:1px #ccc solid;
    padding:0;
    margin:1rem 0;
    input{
      border:none;
      box-shadow: none;
      margin:0;
      box-sizing: border-box !important;
      width: 100%;
    }    
  }
}
.btnstb{
  &-edit{
    border:1px #32383f solid !important;
    color:#32383f;
  }
  &-del{
    border:1px #D7000F solid !important;
    color:#D7000F;
  }
  &-reply{
    border:1px #32383f solid !important;
    color:#fff;
    background: #32383f;
  }
  &-list{
    border:1px #32383f solid !important;
    color:#fff;
    background: #32383f;
    display: inline-block;
    justify-content: flex-end;
    padding-left:2rem !important;
    padding-right:2rem !important;
    // padding:
  }
}
.bg-l25-light{
  background:#f4f4f4;
}
.readed{
  background: #dedfe1;
  color: #fff;
  padding: 0.135em 0.5rem 0.235rem;
  border-radius: 0.235rem;
}
.noti-badge{
  background: #D7000F;
  color: #fff;
  padding: 0.135em 0.5rem 0.235rem;
  border-radius: 0.235rem;
}
.board-btn-zone{
  border-top: 1px #ccc solid;
}
.board-content-zone{
  // border-bottom: 1px #ccc solid;
  padding: 1.3125rem 1.3125rem !important;
  &-hr{
    border-bottom: 1px #ccc solid;
  }
}
.categorybadge {
  padding: 0.5rem;
  margin-left: 3px;
}
.title-text {
  padding-left: 0.5rem !important;
}
.bg-info {
  background-color: #4d69fa !important;
}