.empty{
  &-card{
    border-radius:0.5rem ;
    background: #fff;
    padding: 1.3125rem 1.3125rem;
  }
  &-cal{
    background:url(../../assets/Time.png) no-repeat;
    background-size: contain;
    height:420px;
    width: 364px;
  }
}
.dash{
  &-header{
    padding-bottom:0.2rem !important;
  }
  &-date{
    font-size: 0.875rem;
    color: #999;
  }
  &-name{
    font-size: 0.875rem;
    color: #999;
    text-align: left;
  }
  &-duty{
    font-size: 0.875rem;
    color: #999;
    text-align: left;
  }
}
.img-empty{
  background:url(../../assets/img-empty.png) 50% 50% no-repeat;
  background-size: contain;
  border: 1px #f4f4f4 solid;
}
.thumb-title{
  height:44px;
}

.dlabel {
  display: block;
  padding-left: 20px;
  text-indent: -20px;
}

.dinput {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: 0px;
  *overflow: hidden;
}