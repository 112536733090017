// .aside .navigation{
//   &-link{
//     color:#fff;
//     &:hover{
//       background-color: #fff;
//     }
//     &.active {
//       background-color: #fff;
//       color: #1f2128;
//     }
//   }
//   &-icon{
//     color:#fff;
//     &:hover{
//       color: #D7000F;
//     }
//   }
// }

// .navigation-link-info{
//   i{
//     font-size: 1.25rem;
//     padding:0 0.25rem;
//   }
// }
div.sys-link,
a.sys-link{
  padding:0.5rem 1rem;
  border:1px #D7000F solid;
  border-radius: 8px;
  text-decoration: none !important;
  color:#333;
  background: linear-gradient(90deg, #D7000F 40%, #fff 30%);
  &:hover{
    color:#D7000F;
  }
  i{
    font-size: 1.5rem;
    color:#fff;
  } 
  &-default{
    border:1px #ccc solid;
    color:#ccc;
    background: linear-gradient(90deg, #ccc 40%, #fff 30%);
    cursor: not-allowed;
    &:hover{
      color: #ccc;
    }
  }
}
.header-noti{
  font-size: 1.8rem;
}
input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc !important;
  opacity: 1; /* Firefox */
  font-weight: normal;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc ;
  font-weight: normal;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc !important;
  font-weight: normal;
}
.dropdown-menu{
  min-width: 10rem;
}