//
//  Tables
//
@use 'sass:map';

//  stylelint-disable custom-property-pattern
.table {
	--#{$variable-prefix}table-color: #{$table-color};
	--#{$variable-prefix}table-border-color: #{$table-border-color};
	--#{$variable-prefix}table-bg: #{$table-bg};
	--#{$variable-prefix}table-striped-color: #{$table-striped-color};
	--#{$variable-prefix}table-striped-bg: #{$table-striped-bg};
	--#{$variable-prefix}table-active-color: #{$table-active-color};
	--#{$variable-prefix}table-active-bg: #{$table-active-bg};
	--#{$variable-prefix}table-hover-color: #{$table-hover-color};
	--#{$variable-prefix}table-hover-bg: #{$table-hover-bg};

	border-color: var(--#{$variable-prefix}table-border-color);
	color: var(--#{$variable-prefix}table-color);

	@include dark-mode {
		--#{$variable-prefix}table-color: #{$dark-table-color};
		--#{$variable-prefix}table-border-color: #{$dark-table-border-color};
		--#{$variable-prefix}table-bg: #{$dark-table-bg};
		--#{$variable-prefix}table-striped-color: #{$dark-table-striped-color};
		--#{$variable-prefix}table-striped-bg: #{$dark-table-striped-bg};
		--#{$variable-prefix}table-active-color: #{$dark-table-active-color};
		--#{$variable-prefix}table-active-bg: #{$dark-table-active-bg};
		--#{$variable-prefix}table-hover-color: #{$dark-table-hover-color};
		--#{$variable-prefix}table-hover-bg: #{$dark-table-hover-bg};
	}

	.border-active-end {
		border-right: 1px #e7e7e7 solid !important;
	}
	
	.border-active-bottom {
		border-bottom: 1px #e7e7e7 solid !important;
	}
	
	.border-active-start {
		border-left: 1px #e7e7e7 solid !important;
	}
	
	.border-active-top {
		border-top: 1px #e7e7e7 solid !important;
	}

	.table-sticky {
		white-space: nowrap;
		position: -webkit-sticky;
		position: sticky;
		inset-block-start: 0;
		// @include media-breakpoint-down(map-get($table, mobile-breakpoint)) {
		// 	position: fixed !important;
		// }
	}
}
//  stylelint-enable custom-property-pattern

//  stylelint-disable selector-max-compound-selectors
//  stylelint-disable selector-no-qualifying-type
thead > tr > th svg.ascending {
	transform: rotate(180deg);
}
//  stylelint-enable selector-max-compound-selectors
//  stylelint-enable selector-no-qualifying-type

//  Modern-design
//
//  Rounded corners

.table-modern {
	//  stylelint-disable scss/at-extend-no-missing-placeholder
	//  stylelint-disable  at-rule-disallowed-list
	@extend .table-borderless;
	@extend .table-striped;
	//  stylelint-enable scss/at-extend-no-missing-placeholder

	%table-modern-row {
		@include padding($table-modern-cell-padding-y $table-modern-cell-padding-x);

		vertical-align: $table-modern-cell-vertical-align;

		&:first-child {
			@include border-start-radius($table-modern-border-radius);
		}

		&:last-child {
			@include border-end-radius($table-modern-border-radius);
		}
	}

	//  stylelint-disable selector-max-compound-selectors
	> thead > tr > * {
		//  stylelint-disable custom-property-pattern
		--#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
		//  stylelint-enable custom-property-pattern

		@extend %table-modern-row;

		color: var(--#{$variable-prefix}table-striped-color);
	}

	> tbody > tr > * {
		@extend %table-modern-row;
	}
	//  stylelint-enable at-rule-disallowed-list
	//  stylelint-enable selector-max-compound-selectors
}

